import React, {ComponentType, createContext, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormInstanceData, useFormInstance} from '../../../../Form/useFormInstance';
import {AddressWithContactModel} from '../../../../../../domain/models/checkout/AddressWithContact.model';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {ApiAddressFragment, FullAddressContactDetailsFragment} from '../../../../../../gql/graphql';
import {ADD_NEW_ADDRESS_ID} from '../../../../constants';
import {ContactModel} from '../../../../../../domain/models/checkout/Contact.model';
import {isContactDefined} from '../../../../../../domain/utils/isContactDefined';
import {isShippingDestinationSignificant} from '../../../../../../domain/utils/isShippingDestinationSignificant';
import {AddressModel} from '../../../../../../domain/models/checkout/Address.model';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {FormValues} from '@wix/form-fields';
import {
  getCustomFieldFromAdditionalInfoFormValues,
  getCustomerDetailsFormInitialState,
  getExtendedFieldValuesFromCustomerDetailsForm,
  getEmailFromEmailFormValues,
} from '../../../../Form/CustomerDetailsForm/CustomerDetailsForm.utils';
import {
  getAddressFromAddressFormValues,
  getContactFormCurrentStateWithUpdatedCountry,
  getContactDetailsFromContactFormValues,
} from '../../../../Form/form.utils';
import {SPECS} from '../../../../../../common/constants';

export type CustomerDetailsDataContextType = {
  customerDetailsForm: FormInstanceData;
  isFormValid: () => Promise<boolean>;
  updateContactCountry: (country: string) => void;
  getCustomerDetailsForSubmit: () => {
    contactDetails?: FullAddressContactDetailsFragment;
    email?: string;
    extendedFieldsValue?: any;
    customFieldValue?: string;
    shippingAddress?: ApiAddressFragment;
  };
  contactCountry?: string;
};

export const CustomerDetailsDataContext = createContext({} as CustomerDetailsDataContextType);

export function withCustomerDetailsData<T extends object>(Component: ComponentType<T>) {
  /* eslint-disable-next-line sonarjs/cognitive-complexity */
  return function Wrapper(props: T) {
    const {experiments} = useExperiments();
    const {
      checkoutStore: {checkout, isShippingFlow},
      checkoutSettingsStore: {checkoutSettings},
      memberStore: {isMember, defaultAddressId, addressesInfo},
      formsStore: {dataExtendedFieldsTargets},
    } = useControllerProps();

    function getDefaultAddressFromAddresses(): AddressWithContactModel | undefined {
      return addressesInfo?.addresses.find((address) => address.addressesServiceId === defaultAddressId);
    }

    function getInitialContact(): ContactModel | undefined {
      if (isShippingDestinationSignificant(checkout.shippingDestination, isShippingFlow)) {
        return checkout.shippingDestination?.contact;
      }

      if (!isShippingFlow && isContactDefined(checkout.billingInfo?.contact)) {
        return checkout.billingInfo?.contact;
      }

      if (!isMember) {
        return checkout.shippingDestination?.contact;
      }

      return getDefaultAddressFromAddresses()?.contact;
    }

    function getInitialAddress(): AddressModel | undefined {
      if (!isMember || isShippingDestinationSignificant(checkout.shippingDestination, isShippingFlow)) {
        return checkout.shippingDestination?.address;
      }

      return getDefaultAddressFromAddresses()?.address;
    }

    const {editMode, selectedAddressesService, selectedAddressesServiceId} = useMemberDetailsData();

    const customerDetailsForm = useFormInstance(
      () =>
        getCustomerDetailsFormInitialState({
          checkoutSettings,
          address: getInitialAddress(),
          buyerInfo: checkout.buyerInfo,
          contact: getInitialContact(),
          customField: checkout.customField,
          extendedFields: checkout.extendedFields,
        }) as FormValues
    );

    const isFormValid = async () => customerDetailsForm.isValid();

    const areFormsRendered = () => customerDetailsForm.isRendered();

    const getCustomerDetailsForSubmit = () => {
      const addressWithContact = areFormsRendered()
        ? {
            contactDetails: getContactDetailsFromContactFormValues(customerDetailsForm.data.formValues),
            shippingAddress: isShippingFlow
              ? getAddressFromAddressFormValues(customerDetailsForm.data.formValues)
              : undefined,
          }
        : {};
      return {
        ...addressWithContact,
        email: !isMember ? getEmailFromEmailFormValues(customerDetailsForm.data.formValues) : checkout.buyerInfo.email,
        ...{
          extendedFieldsValue: getExtendedFieldValuesFromCustomerDetailsForm(
            customerDetailsForm.data.formValues,
            dataExtendedFieldsTargets
          ),
        },
        customFieldValue: getCustomFieldFromAdditionalInfoFormValues(customerDetailsForm.data.formValues),
      };
    };

    const initForm = (shippingDestination: AddressWithContactModel) => {
      customerDetailsForm.data.setFormValues(
        getCustomerDetailsFormInitialState({
          checkoutSettings,
          address: shippingDestination?.address,
          contact: shippingDestination?.contact,
          customField: checkout.customField,
          extendedFields: checkout.extendedFields,
        }) as FormValues
      );
    };

    const [contactCountry, setContactCountry] = useState(checkout.shippingDestination?.address?.country);

    const updateContactCountry = (country: string) => {
      setContactCountry(country);

      customerDetailsForm.data.setFormValues(
        getContactFormCurrentStateWithUpdatedCountry({
          contactFormValues: customerDetailsForm.data.formValues,
          country,
        })
      );
    };

    const wrapWithCheckoutHackForReact18Tests = (fn: () => void) => {
      /* istanbul ignore else */
      if (experiments.enabled(SPECS.WithCheckoutHackForReact18Tests)) {
        return setTimeout(fn);
      } else {
        fn();
      }
    };

    useEffect(
      () => {
        if (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) {
          let contactDetailsForFirstTimeMember: ContactModel | undefined;
          if (
            isMember &&
            addressesInfo.addresses.length === 0 &&
            !isShippingFlow &&
            isContactDefined(checkout.billingInfo?.contact)
          ) {
            contactDetailsForFirstTimeMember = checkout.billingInfo?.contact;
          }
          wrapWithCheckoutHackForReact18Tests(() =>
            initForm(
              new AddressWithContactModel({
                addressesServiceId: ADD_NEW_ADDRESS_ID,
                contactDetails: contactDetailsForFirstTimeMember
                  ? new ContactModel(contactDetailsForFirstTimeMember)
                  : undefined,
              })
            )
          );
        } else if (editMode) {
          wrapWithCheckoutHackForReact18Tests(() => initForm(selectedAddressesService!));
        }
      },
      /* eslint-disable react-hooks/exhaustive-deps*/ [editMode, selectedAddressesServiceId]
    );

    return (
      <CustomerDetailsDataContext.Provider
        value={{
          customerDetailsForm,
          isFormValid,
          getCustomerDetailsForSubmit,
          updateContactCountry,
          contactCountry,
        }}>
        <Component {...props} />
      </CustomerDetailsDataContext.Provider>
    );
  };
}

export function useCustomerDetailsData() {
  const {customerDetailsForm, isFormValid, getCustomerDetailsForSubmit, updateContactCountry, contactCountry} =
    useContext(CustomerDetailsDataContext);

  return {
    customerDetailsFormData: customerDetailsForm.data,
    isFormValid,
    getCustomerDetailsForSubmit,
    updateContactCountry,
    contactCountry,
  };
}
