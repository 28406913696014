/* eslint-disable-next no-shadow */
export const CHECKOUT_WIDGET_ID = '14fd5970-8072-c276-1246-058b79e70c1a';

export enum FedopsInteractions {
  ApplyCouponInteraction = 'apply-coupon-interaction',
  ApplyGiftCardInteraction = 'apply-gift-card-interaction',
  PlaceOrderInteraction = 'place-order-interaction',
  ValidatePaymentInteraction = 'validate-payment-interaction',
  InitializePaymentInPlaceOrderInteraction = 'initialize-payment-in-place-order-interaction',
  ChangeDeliveryMethodInteraction = 'change-delivery-method-interaction',
  SubmitCustomerDetailsInteraction = 'submit-customer-details-interaction',
  InitFormsInteraction = 'init-forms-interaction',
  FetchCheckout = 'fetch-checkout',
  SetBillingAndPaymentInteraction = 'set-billing-and-payment-interaction',
}

export enum FieldMasks {
  customField = 'customFields',
  extendedFields = 'extendedFields',
  buyerInfoEmail = 'buyerInfo.email',
  shippingContact = 'shippingInfo.shippingDestination.contactDetails',
  shippingAddress = 'shippingInfo.shippingDestination.address',
  shippingAddressesServiceId = 'shippingInfo.shippingDestination.addressesServiceId',
  billingContact = 'billingInfo.contactDetails',
  billingAddress = 'billingInfo.address',
  billingAddressesServiceId = 'billingInfo.addressesServiceId',
  selectedCarrierServiceOption = 'shippingInfo.selectedCarrierServiceOption',
}

export enum AddressesFieldMasks {
  firstName = 'fullName.firstName',
  lastName = 'fullName.lastName',
  company = 'company',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  country = 'country',
  subdivision = 'subdivision',
  zipCode = 'zipCode',
  phoneNumber = 'phoneNumber',
  setAsDefault = 'setAsDefault',
  taxId = 'taxInfo.id',
  taxType = 'taxInfo.type',
  streetName = 'street.name',
  streetNumber = 'street.number',
}

export const FIELDS_GAP = 20;

export const DELIVERY_METHOD_GROUP_NAME = 'deliveryOptions';

export const MAX_CUSTOM_FIELD_VALUE_LENGTH = 250;
export const MAX_ZIP_CODE_FIELD_LENGTH = 250;

export const ONE_COLUMN_VIEW_WIDTH_THRESHOLD = 750;

export const PAYPAL = 'PayPal';

export const FORMS_TEMPLATE_IDS = {
  BILLING: '40f8c94a-07d8-453b-b470-d26508a8da97',
  CUSTOMER_DETAILS_W_ADDITIONAL_INFO: 'a19435ae-880c-4f83-8062-3e8b19832a54',
  CUSTOMER_DETAILS_WO_ADDITIONAL_INFO: '3f63d41e-9b8e-4631-bfe0-e984965ad618',
};

export const FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER = {
  EXTENDED_FIELDS_FORM_ID: '391eeac7-0183-4955-8663-99bb01ab0ab4',
};

export const FORMS_CHECKOUT_NAMESPACE = 'wix.ecom.v1.checkout';
export const FORMS_CHECKOUT_DEXT_FQDN = 'wix.ecom.*.checkout';

export const DEFAULT_COUNTRY = 'US';

export const COUNTRIES_WITH_VAT = ['BR', 'IL'];
export const COUNTRIES_WITH_VAT_OLD = ['BR'];

export const ADD_NEW_ADDRESS_ID = 'addNewAddress';

export enum THEMES {
  DONATIONS = 'donations',
}
