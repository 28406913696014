import {classes} from './PaymentAndPlaceOrderStep.st.css';
import {PaymentsWidget} from '@wix/cashier-payments-widget/lazy';
import {getMandatoryFieldsByCheckout} from '../../../../../domain/utils/cashier.utils';
import React, {useEffect} from 'react';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {usePaymentsApi} from '../../../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {StartPayment} from '../../../../../domain/utils/analytics.utils';
import {getPaymentsWidgetBIData} from '../../../../../domain/utils/bi.util';
import {StepState} from '../../../../../types/checkoutApp.types';
import {getCountryFromLocaleDataset} from '../../../../../domain/utils/localeDataset.util';
import {MFieldValuePairs} from '@wix/cashier-payments-widget/src/types/MandatoryFields';
import {useBillingData} from './WithBillingData';

export enum PaymentWidgetWrapperDataHooks {
  root = 'PaymentWidgetWrapperDataHooks.root',
  emptyRoot = 'PaymentWidgetWrapperDataHooks.emptyRoot',
}

export const PaymentWidgetWrapper = ({stepState}: {stepState: StepState}) => {
  const {
    paymentStore: {cashierWidgetProps},
    navigationStore: {trackEvent, isSSR},
    checkoutStore: {checkout},
  } = useControllerProps();
  const {setPaymentsApi, setActivePaymentId} = usePaymentsApi();
  const {contactCountry} = useBillingData();

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        trackEvent(...StartPayment);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  const wrapperClass: {[state: string]: string} = {
    [StepState.EMPTY]: classes.paymentEmpty,
    [StepState.OPEN]: classes.paymentOpen,
  };

  /* istanbul ignore if */
  if (!cashierWidgetProps || isSSR) {
    return <div className={wrapperClass[stepState]} data-hook={PaymentWidgetWrapperDataHooks.emptyRoot}></div>;
  }

  const mandatoryFields: MFieldValuePairs = getMandatoryFieldsByCheckout(checkout, contactCountry);

  const isWidgetHidden = stepState === StepState.EMPTY;

  const {configuration, ...cashierProps} = cashierWidgetProps;
  const country3 = contactCountry ? getCountryFromLocaleDataset(contactCountry)?.key : undefined;
  return (
    <div className={wrapperClass[stepState]} data-hook={PaymentWidgetWrapperDataHooks.root}>
      <PaymentsWidget
        {...cashierProps}
        biData={getPaymentsWidgetBIData(checkout)}
        configuration={{...configuration, country: country3}}
        isWidgetVisible={!isWidgetHidden}
        isFullWidth={true}
        mandatoryFields={mandatoryFields}
        onApiInit={setPaymentsApi}
        paymentMethodChanged={setActivePaymentId}
        paymentResult={/* istanbul ignore next */ () => null}
      />
    </div>
  );
};
