import React, {useState} from 'react';
import {Checkbox, Text} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../Widget/ControllerContext';
import {ILocaleKeys, useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {PoliciesText, PolicyInfo} from './PoliciesText/PoliciesText';
import {ValueEnabledModel} from '../../../domain/models/common/ValueEnabled.model';
import {classes} from './Checkboxes.st.css';
import {
  PolicyButtonLocation,
  PolicyType,
} from '../../../common/components/PolicyButtonWithDialog/PolicyButtonWithDialog';
import {AgreeToTermsAnalyticsEventParams} from '../../../domain/utils/analytics.utils';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {PaymentPolicyDescription} from '../../../common/components/PaymentPolicyDescription/PaymentPolicyDescription';
import {ValueEnabledTitleModel} from '../../../domain/models/common/ValueEnabledTitle.model';
import {PaymentPolicyModel} from '../../../domain/models/common/PaymentPolicy.model';
import {SPECS} from '../../../common/constants';

export enum CheckboxesDataHooks {
  root = 'CheckboxesDataHooks.root',
  policyCheckbox = 'CheckboxesDataHooks.policyCheckbox',
  paymentPolicyCheckbox = 'CheckboxesDataHooks.paymentPolicyCheckbox',
  subscriptionCheckbox = 'CheckboxesDataHooks.subscriptionCheckbox',
  digitalPolicyCheckbox = 'CheckboxesDataHooks.digitalPolicyCheckbox',
  policyLabel = 'CheckboxesDataHooks.policyLabel',
  paymentPolicyLabel = 'CheckboxesDataHooks.paymentPolicyLabel',
  digitalPolicyLabel = 'CheckboxesDataHooks.digitalPolicyLabel',
  subscriptionLabel = 'CheckboxesDataHooks.subscriptionLabel',
  TermsAndConditionsButton = 'CheckboxesDataHooks.TermsAndConditionsButton',
  PrivacyPolicyButton = 'CheckboxesDataHooks.PrivacyPolicyButton',
  ReturnPolicyButton = 'CheckboxesDataHooks.ReturnPolicyButton',
  DigitalPolicyButton = 'CheckboxesDataHooks.DigitalPolicyButton',
  PaymentPolicyButton = 'CheckboxesDataHooks.PaymentPolicyButton',
  CustomPolicyButton = 'CheckboxesDataHooks.CustomPolicyButton',
}

export const Checkboxes = ({checkboxesRef}: {checkboxesRef?: React.RefObject<HTMLDivElement>}) => {
  const {
    checkoutSettingsStore: {checkoutSettings},
    checkoutStore: {checkout},
    navigationStore: {trackEvent},
    checkboxesStore: {
      toggleIsPolicyCheckboxChecked,
      toggleIsDigitalPolicyCheckboxChecked,
      toggleIsSubscriptionCheckboxChecked,
      toggleIsPaymentPolicyCheckboxChecked,
      isDigitalPolicyCheckboxChecked,
      isPolicyCheckboxChecked,
      isPaymentPolicyCheckboxChecked,
      isSubscriptionCheckboxChecked,
      wasFormSubmitted,
    },
  } = useControllerProps();
  const {termsAndConditions, privacyPolicy, returnPolicy, digitalPolicy, customPolicy} = checkoutSettings;
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();

  const shouldShowPolicyCheckbox =
    checkoutSettings.areGeneralPoliciesEnabled && !checkout.customSettings?.hidePolicyAgreementCheckbox;

  const [policies] = useState(
    termsFieldLabels({termsAndConditions, privacyPolicy, returnPolicy, customPolicy}, localeKeys)
  );

  const onPolicyChanged = ({checked}: {checked: boolean}) => {
    trackEvent(...AgreeToTermsAnalyticsEventParams);
    toggleIsPolicyCheckboxChecked(checked);
  };

  const onDigitalPolicyChanged = ({checked}: {checked: boolean}) => {
    trackEvent(...AgreeToTermsAnalyticsEventParams);
    toggleIsDigitalPolicyCheckboxChecked(checked);
  };

  const onPaymentPolicyChanged = ({checked}: {checked: boolean}) => {
    toggleIsPaymentPolicyCheckboxChecked(checked);
  };

  return (
    <div data-hook={CheckboxesDataHooks.root} className={classes.root} ref={checkboxesRef}>
      {shouldShowPolicyCheckbox && (
        <Checkbox
          required={true}
          error={!isPolicyCheckboxChecked && wasFormSubmitted}
          errorMessage={localeKeys.checkout.terms_and_condition.checkbox_error()}
          newErrorMessage={!isPolicyCheckboxChecked && wasFormSubmitted}
          data-hook={CheckboxesDataHooks.policyCheckbox}
          label={<PoliciesText dataHook={CheckboxesDataHooks.policyLabel} policies={policies} />}
          checked={isPolicyCheckboxChecked}
          onChange={onPolicyChanged}
        />
      )}
      {checkout.paymentPolicies.length > 0 && experiments.enabled(SPECS.SupportCardTokenizationOnCartAndCheckout) && (
        <Checkbox
          required={true}
          error={!isPaymentPolicyCheckboxChecked && wasFormSubmitted}
          errorMessage={localeKeys.checkout.payment_policy.checkbox_error()}
          newErrorMessage={!isPaymentPolicyCheckboxChecked && wasFormSubmitted}
          data-hook={CheckboxesDataHooks.paymentPolicyCheckbox}
          label={
            <PoliciesText
              dataHook={CheckboxesDataHooks.paymentPolicyLabel}
              policies={[paymentPolicyInfo(localeKeys, checkout.paymentPolicies)]}
              labelKey={'checkout.payment_policy.checkbox.label'}
            />
          }
          checked={isPaymentPolicyCheckboxChecked}
          onChange={onPaymentPolicyChanged}
        />
      )}
      {checkoutSettings.isDigitalPolicyEnabled && checkout.hasDigitalItems && (
        <Checkbox
          required={true}
          error={!isDigitalPolicyCheckboxChecked && wasFormSubmitted}
          errorMessage={localeKeys.checkout.terms_and_condition.checkbox_error()}
          newErrorMessage={!isPolicyCheckboxChecked && wasFormSubmitted}
          data-hook={CheckboxesDataHooks.digitalPolicyCheckbox}
          label={
            <PoliciesText
              dataHook={CheckboxesDataHooks.digitalPolicyLabel}
              policies={[digitalPolicyInfo(digitalPolicy, localeKeys)]}
            />
          }
          checked={isDigitalPolicyCheckboxChecked}
          onChange={onDigitalPolicyChanged}
        />
      )}
      {checkoutSettings.isSubscriptionEnabled && (
        <Checkbox
          data-hook={CheckboxesDataHooks.subscriptionCheckbox}
          label={
            <Text data-hook={CheckboxesDataHooks.subscriptionLabel}>{localeKeys.checkout.subscription.checkbox()}</Text>
          }
          checked={isSubscriptionCheckboxChecked}
          onChange={({checked}) => toggleIsSubscriptionCheckboxChecked(checked)}
        />
      )}
    </div>
  );
};

const termsFieldLabels = (
  {
    termsAndConditions,
    privacyPolicy,
    returnPolicy,
    customPolicy,
  }: {
    termsAndConditions: ValueEnabledModel;
    privacyPolicy: ValueEnabledModel;
    returnPolicy: ValueEnabledModel;
    customPolicy: ValueEnabledTitleModel;
  },
  localeKeys: ILocaleKeys
): PolicyInfo[] =>
  [
    {
      enabled: termsAndConditions.enabled,
      value: () => termsAndConditions.value,
      labelKey: 'checkout.terms_and_conditions.checkbox.terms_and_conditions',
      dialogTitle: localeKeys.checkout.terms_and_condition_modal.title(),
      dataHook: CheckboxesDataHooks.TermsAndConditionsButton,
      policyType: PolicyType.TermsAndConditions,
      location: PolicyButtonLocation.FORM,
    },
    {
      enabled: privacyPolicy.enabled,
      value: () => privacyPolicy.value,
      labelKey: 'checkout.terms_and_conditions.checkbox.privacy_policy',
      dialogTitle: localeKeys.checkout.privacy_policy_modal.title(),
      dataHook: CheckboxesDataHooks.PrivacyPolicyButton,
      policyType: PolicyType.PrivacyPolicy,
      location: PolicyButtonLocation.FORM,
    },
    {
      enabled: returnPolicy.enabled,
      value: () => returnPolicy.value,
      labelKey: 'checkout.terms_and_conditions.checkbox.return_policy',
      dialogTitle: localeKeys.checkout.refund_cancellation_modal.title(),
      dataHook: CheckboxesDataHooks.ReturnPolicyButton,
      policyType: PolicyType.ReturnPolicy,
      location: PolicyButtonLocation.FORM,
    },

    {
      enabled: customPolicy.enabled,
      value: () => customPolicy.value,
      labelKey: localeKeys.checkout.policies.checkbox.custom_policy({customPolicyTitle: customPolicy.title}),
      dialogTitle: customPolicy.title,
      dataHook: CheckboxesDataHooks.CustomPolicyButton,
      policyType: PolicyType.CustomPolicy,
      location: PolicyButtonLocation.FORM,
    },
  ].filter(({enabled}) => enabled);

const digitalPolicyInfo = (digitalPolicy: ValueEnabledModel, localeKeys: ILocaleKeys): PolicyInfo => ({
  enabled: digitalPolicy.enabled,
  value: () => digitalPolicy.value,
  labelKey: 'checkout.terms_and_conditions.checkbox.digital_item_policy',
  dialogTitle: localeKeys.checkout.digital_item_policy_modal.title(),
  dataHook: CheckboxesDataHooks.DigitalPolicyButton,
  policyType: PolicyType.DigitalItemPolicy,
  location: PolicyButtonLocation.FORM,
});

const paymentPolicyInfo = (localeKeys: ILocaleKeys, paymentPolicies: PaymentPolicyModel[]): PolicyInfo => ({
  enabled: true,
  value: () => <PaymentPolicyDescription paymentPolicies={paymentPolicies} />,
  labelKey: 'checkout.policies.checkbox.payment_policy',
  dialogTitle: localeKeys.checkout.payment_policy_modal.title(),
  dataHook: CheckboxesDataHooks.PaymentPolicyButton,
  policyType: PolicyType.PaymentPolicy,
  location: PolicyButtonLocation.FORM,
});
